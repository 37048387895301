import SelectCompanyMenu from '@/baseline/navigation/desktop/companyMenu';
import HelpButton from '@/baseline/navigation/desktop/helpButton';
import LanguageButton from '@/baseline/navigation/desktop/languageButton';
import ProfileButton from '@/baseline/navigation/desktop/profileButton';
import ElevationScroll from '@/components/elevationScroll';
import { Box, Container, Stack } from '@mui/material';
import React, { Fragment, ReactNode } from 'react';

export default function SubscriptionLayout( { children }: { children?: ReactNode } ) {
	return (
		<Fragment>
			<ElevationScroll>
				<Container maxWidth='xl' sx={{ py: 1 }}>
					<Stack
						direction='row'
						alignItems='center'
						spacing={2}>
						<SelectCompanyMenu/>
						<Box display='flex' flexGrow={1}/>
						<Stack
							direction='row'
							alignItems='center'
							spacing={2}>
							<HelpButton/>
							<LanguageButton/>
							<ProfileButton/>
						</Stack>
					</Stack>
				</Container>
			</ElevationScroll>
			{children}
		</Fragment>
	);
}
