import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import LayoutAnimation from '@/components/framerAnimations/layoutAnimation';
import handleGoNext from '@/helpers/formikValidateBeforeSubmit';
import useShowSnackBarAfterReload from '@/hooks/useShowSnackBarAfterReload';
import CompanyFormDetails from '@/pages/welcome/company';
import CompanyExtraDetails from '@/pages/welcome/companyExtraDetails';
import useNewCompanyHandleSubmit from '@/pages/welcome/handleSubmit';
import AddressFormDetails from '@/pages/welcome/location';
import { onBoardingFormInitialValues, onboardingFormValidationSchemas } from '@/pages/welcome/welcomeOnBoardingForm';
import StepperProvider, { useStepper } from '@/providers/stepper';
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	LinearProgress,
	Stack,
	Typography,
} from '@mui/material';
import { Fragment } from 'react';

export default function NewCompanyFormModal() {
	return (
		<StepperProvider>
			<NewCompanyForm/>
		</StepperProvider>
	);
}

function NewCompanyForm() {
	const { setActiveStep, activeStep } = useStepper();
	const handleSubmitForm = useNewCompanyHandleSubmit();
	const showSnackBarAfterReload = useShowSnackBarAfterReload();
	return (
		<Form
			validationSchema={onboardingFormValidationSchemas[ activeStep ]}
			initialValues={onBoardingFormInitialValues}
			onSubmit={async ( values ) => {
				await handleSubmitForm( values );
				window.open( '/dashboard', '_self' );
				showSnackBarAfterReload( `New company ${values.company.name} was created.`, { variant: 'default' } );
			}}>
			{( formik ) => (
				<Fragment>
					<DialogTitle>
						<Typography variant='h3'>
							Create New Company
						</Typography>
					</DialogTitle>
					<Box sx={{ mb: 1 }}>
						<LinearProgress
							variant='determinate'
							value={( activeStep + 1 ) * 33.33}
							sx={{ height: 3, borderRadius: 0 }}
						/>
					</Box>
					<DialogContent>
						<LayoutAnimation
							key={
								activeStep === 0 ? 'Company'
									: activeStep === 1 ? 'Location'
										: activeStep === 2 ? 'CompanyExtra'
											: 'Gateway'
							}
							variant='fadeInRight'>
							{activeStep === 0 && <CompanyFormDetails/>}
							{activeStep === 1 && <AddressFormDetails/>}
							{activeStep === 2 && <CompanyExtraDetails/>}
						</LayoutAnimation>
					</DialogContent>
					<DialogActions>
						<Stack
							width='100%'
							px={3}
							direction='row'
							alignItems='center'
							justifyContent='space-between'>
							{activeStep !== 0 ? (
								<Button
									variant='outlined'
									size='large'
									color='primary'
									onClick={() => setActiveStep( activeStep - 1 )}>
									Back
								</Button>
							) : <div/>}
							<AsyncLoadingButton
								color='primary'
								variant='contained'
								size='large'
								loading={formik.isSubmitting}
								disabled={activeStep === 2 && ( !formik.values.company?.industry
									|| !formik.values.company?.referralSource
									|| formik.values.company?.referralSource === 'Other' )}
								onClick={async () => await handleGoNext( setActiveStep, formik, activeStep, 2 )}>
								{activeStep === 2 ? 'Create Company' : 'Next'}
							</AsyncLoadingButton>
						</Stack>
					</DialogActions>
				</Fragment>
			)}
		</Form>
	);
}

