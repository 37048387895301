import { OptionsObject, SnackbarMessage, useSnackbar } from 'notistack';
import { useEffect } from 'react';

type SnackbarProps = {
	message: SnackbarMessage,
	snackbarProps: OptionsObject
};

type UseShowSnackBarAfterReloadHook = ( message: string, snackbarProps?: OptionsObject ) => void;

export default function useShowSnackBarAfterReload(): UseShowSnackBarAfterReloadHook {
	const { enqueueSnackbar } = useSnackbar();
	
	useEffect( () => {
		const snackbarData = localStorage.getItem( 'showSnackbar' );
		
		if ( snackbarData ) {
			const { message, snackbarProps = {} }: SnackbarProps = JSON.parse( snackbarData );
			enqueueSnackbar( message, { variant: 'success', autoHideDuration: 3000, ...snackbarProps } );
			localStorage.removeItem( 'showSnackbar' );
		}
	}, [] );
	
	return ( message: string, snackbarProps: OptionsObject = {} ) => {
		localStorage.setItem( 'showSnackbar', JSON.stringify( { message, snackbarProps } ) );
	};
}
