import { useCompany } from '@/hooks/useSetCompanyInAtom';
import useUserInfo from '@/providers/auth/useUserInfo';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import { getSubscription } from './getSubscription';

type UseSubscription = {
	active: boolean,
	staffDisabled?: boolean,
	daysLeft?: number
};

export default function useSubscription(): UseSubscription {
	const { user, staff } = useUserInfo();
	const { company: sessionCompany, loading } = useCompany();
	
	if ( loading ) return { active: true };
	
	const company = sessionCompany || staff?.company;
	if ( isSuperAdmin( user ) ) return { active: true };
	if ( !staff ) return { active: true }; // new user
	
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	if ( !subscription ) return { active: false };
	
	const active = subscription.status === 'ACTIVE';
	
	const daysLeft = subscription.endDate
		? differenceInCalendarDays( new Date( subscription.endDate ), new Date() )
		: undefined;
	
	let staffDisabled = Boolean( staff.disabled );
	
	// TODO: Remove this check after warning period
	if ( staffDisabled && subscription?.endDate && isAfter( new Date( subscription.endDate ), new Date() ) ) {
		staffDisabled = false;
	}
	
	return {
		active,
		daysLeft,
		staffDisabled,
	};
}
