import { getSubscription } from '@/baseline/subscription/getSubscription';
import { TierObj } from '@/baseline/subscription/tiers';
import useSubscription from '@/baseline/subscription/useSubscription';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import Loading from '@/components/loading';
import { useGraphQL } from '@/data';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import type { QueryTierReadArgs, Subscription } from '@/types/schema';
import { SubscriptionTier } from '@/types/tiers';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import { Stack, useTheme } from '@mui/material';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe, type PaymentIntent } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';

type Props = {
	selectedTier: TierObj,
	onFinish: ( paymentIntent: PaymentIntent ) => void
};

function PaymentForm( { selectedTier, onFinish }: Props ) {
	const stripe = useStripe();
	const elements = useElements();
	
	if ( !stripe || !elements ) return <Loading/>;
	
	return (
		<Stack spacing={2} sx={{ my: 3 }}>
			<PaymentElement/>
			<AsyncLoadingButton
				fullWidth
				color='primary'
				variant='contained'
				size='large'
				sx={{ height: 50, borderRadius: 3 }}
				style={{ fontSize: 15, fontWeight: 'normal' }}
				onClick={async () => {
					const { error, paymentIntent } = await stripe.confirmPayment( {
						elements,
						confirmParams: { return_url: window.location.href },
						redirect     : 'if_required',
					} );
					if ( error ) throw new Error( error.message );
					if ( paymentIntent?.status !== 'succeeded' ) {
						throw new Error( `Payment failed with status: ${paymentIntent?.status}` );
					}
					await wait( 10000 ); // wait for stripe webhook to update subscription
					onFinish( paymentIntent );
				}}>
				Selected {selectedTier.name} plan! Continue to Pay
			</AsyncLoadingButton>
		</Stack>
	);
}

function CreateSubscription( { selectedTier, companyId, onFinish }: {
	companyId: string,
	selectedTier: TierObj,
	onFinish: ( paymentIntent: PaymentIntent ) => void
} ) {
	const theme = useTheme();
	
	const stripe = useMemo( () => loadStripe( process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!, {
		apiVersion: '2023-10-16',
	} ), [] );
	
	const { data: secret, isLoading } = useQuery(
		[ 'createStripeSubscription', selectedTier.name ],
		() => axios.post( '/api/user/stripe/createSubscription', {
			companyId,
			tierName: selectedTier.name,
		} ).then( ( { data } ) => data.clientSecret ),
		{
			staleTime: 1000 * 60 * 60 * 24, // stripe secret is valid for 24 hours
		} );
	
	if ( !secret || isLoading ) return <Loading/>;
	
	return (
		<Elements
			stripe={stripe}
			options={{
				clientSecret: secret,
				appearance  : { theme: theme.palette.mode === 'dark' ? 'night' : 'flat' },
			}}>
			<PaymentForm
				selectedTier={selectedTier}
				onFinish={onFinish}
			/>
		</Elements>
	);
}

function SwitchSubscription( { subscription, selectedTier, onFinish }: {
	subscription: Subscription,
	selectedTier: TierObj,
	onFinish: ( paymentIntent?: PaymentIntent ) => void
} ) {
	const isUpgrade = selectedTier.price > subscription.tier.price;
	
	const { data } = useGraphQL<QueryTierReadArgs>( {
		queryKey : [ 'tier', selectedTier.name ],
		query    : gql`
			query TierRead_5775($id: String, $name: String) {
				tierRead(id: $id, name: $name) {
					id
				}
			}
		`,
		variables: { name: selectedTier.name },
	} );
	
	if ( !data?.tierRead ) return <Loading/>;
	
	return (
		<AsyncLoadingButton
			fullWidth
			color='primary'
			variant='contained'
			size='large'
			sx={{ height: 50, borderRadius: 3, mt: 2 }}
			style={{ fontSize: 15, fontWeight: 'normal' }}
			onClick={async () => {
				await axios.post( '/api/user/stripe/updateSubscription', {
					subscriptionId: subscription.id,
					newTierId     : data.tierRead.id,
				} );
				await wait( 10000 ); // wait for stripe webhook to update subscription
				onFinish();
			}}>
			{isUpgrade ? 'Upgrade' : 'Downgrade'} to {selectedTier.name} plan!
		</AsyncLoadingButton>
	);
}

export default function StripeSubscription( { selectedTier, onFinish }: Props ) {
	const { company } = useCompany();
	const { active } = useSubscription();
	
	if ( !company ) return <Loading/>;
	
	const subscription = getSubscription( { subscriptions: company.subscriptions, type: 'STRIPE' } );
	const currentSubscribedTier = subscription?.tier;
	
	const isCreate = !subscription || !active || currentSubscribedTier?.name === SubscriptionTier.TRIAL;
	
	if ( isCreate ) return <CreateSubscription companyId={company.id} selectedTier={selectedTier} onFinish={onFinish}/>;
	
	return (
		<SwitchSubscription
			subscription={subscription}
			selectedTier={selectedTier}
			onFinish={onFinish}
		/>
	);
}
