import PageLinkComponent from '@/components/page/linkComponent';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { useModal } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import {
	ContactSupport as ContactSupportIcon,
	FeaturedPlayListRounded as FeaturedPlayListRoundedIcon,
	FeedbackRounded as FeedbackRoundedIcon,
	HelpRounded as HelpRoundedIcon,
	InstallMobile as InstallMobileIcon,
	QueuePlayNext as QueuePlayNextIcon,
	Support as SupportIcon,
	SupportAgent as SupportAgentIcon,
} from '@mui/icons-material';
import { Button, Divider, ListItemIcon, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import useGetDeviceInfo from '../../../hooks/useGetDeviceInfo';
import FeedbackModal from '../../../modals/feedbackModal';

function CoBrowseGenerateCodeModal() {
	const [ code, setCode ] = useState( null );
	
	useAsyncEffect( async () => {
		if ( typeof window !== 'undefined' && window.CobrowseIO ) {
			try {
				const client = await window.CobrowseIO.client();
				const sessionCode = await client.createSessionCode();
				setCode( sessionCode );
			} catch ( error ) {
				console.error( 'Error generating Cobrowse session code:', error );
			}
		}
	}, [] );
	
	return (
		<ResponsiveModalContainer title='Support Code'>
			{code ? (
				<Fragment>
					<Typography variant='h3'>
						{`Support Code: ${code}`}
					</Typography>
					<Typography color='text.secondary'>
						Share this code with the support agent to allow them to view your screen. Do not share this code with
						anyone
						else.
					</Typography>
				</Fragment>
			) : (
				<Typography variant='h3'>
					Start chatting . Please try again later.
				</Typography>
			)}
		</ResponsiveModalContainer>
	);
}

export default function HelpButton() {
	const { isCloverDevice } = useGetDeviceInfo();
	const { showModal } = useModal();
	const { staff } = useUserInfo();
	const { t } = useTranslation();
	const { showMenu } = useMenu();
	
	return (
		<Tooltip title='Get Help'>
			<Button
				startIcon={<HelpRoundedIcon/>}
				sx={{
					'borderRadius': '20px !important',
					'height'      : '28px !important',
					'bgcolor'     : ( theme ) => theme.palette.divider + ' !important',
					'boxShadow'   : 'none !important',
					':hover'      : {
						bgcolor: 'divider',
						filter : 'brightness(110%)',
					},
				}}
				size='small'
				onClick={( e ) =>
					showMenu(
						( { closeMenu } ) => (
							<MenuList>
								{!isCloverDevice && (
									<MenuItem component={PageLinkComponent} href='/articles' target='_blank'>
										<ListItemIcon>
											<ContactSupportIcon/>
										</ListItemIcon>
										{t( 'common:chat-support' )}
									</MenuItem>
								)}
								<MenuItem component={PageLinkComponent} href='tel:18449999203'>
									<ListItemIcon>
										<SupportAgentIcon/>
									</ListItemIcon>
									+1 (844) 999-9203
								</MenuItem>
								{!isCloverDevice && staff && (
									<MenuItem component={PageLinkComponent} href='/articles' target='_blank'>
										<ListItemIcon>
											<SupportIcon/>
										</ListItemIcon>
										{t( 'common:visit-faq' )}
									</MenuItem>
								)}
								{!isCloverDevice && staff && (
									<MenuItem
										component={PageLinkComponent}
										href='https://invoiss.canny.io/feature-requests'
										target='_blank'>
										<ListItemIcon>
											<FeaturedPlayListRoundedIcon/>
										</ListItemIcon>
										{t( 'Feature Request' )}
									</MenuItem>
								)}
								<MenuItem
									onClick={() => {
										closeMenu();
										showModal( FeedbackModal, { maxWidth: 'sm' } );
									}}>
									<ListItemIcon>
										<FeedbackRoundedIcon/>
									</ListItemIcon>
									{t( 'Give Us Feedback' )}
								</MenuItem>
								<Divider/>
								{!isCloverDevice && (
									<MenuItem component={PageLinkComponent} href='/install' target='_blank'>
										<ListItemIcon>
											<InstallMobileIcon/>
										</ListItemIcon>
										{t( 'common:download-app' )}
									</MenuItem>
								)}
								
								<MenuItem
									onClick={() => {
										closeMenu();
										showModal( CoBrowseGenerateCodeModal, { maxWidth: 'xs' } );
									}}>
									<ListItemIcon>
										<QueuePlayNextIcon/>
									</ListItemIcon>
									Remote access code
								</MenuItem>
							</MenuList>
						),
						e.currentTarget,
						{
							anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
							transformOrigin: { vertical: 'top', horizontal: 'center' },
						},
					)}>
				{t( 'common:get-help' )}
			</Button>
		</Tooltip>
	);
}
