import { Box, BoxProps, Stack, Typography } from '@mui/material';

export default function LargeChip( {
	label,
	color,
	hasBorder,
	icon,
	...props
}: {
	label: string | JSX.Element,
	icon?: JSX.Element,
	color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'amethyst',
	hasBorder?: boolean
} & BoxProps ) {
	
	return (
		<Box
			{...props}
			sx={{
				'border'                : hasBorder ? 1 : 0,
				'bgcolor'               : `alpha.${color}`,
				'borderRadius'          : 2,
				'borderColor'           : `${color}.main`,
				'width'                 : 'fit-content',
				'p'                     : 1,
				'.MuiTypography-root, p': {
					color: `${color}.main`,
				},
				...props?.sx,
			}}>
			<Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
				{icon}
				{typeof label === 'string' ? (
					<Typography>
						{label}
					</Typography>
				) : label}
			</Stack>
		</Box>
	);
}
