import SubscriptionLayout from '@/baseline/subscription/_layout';
import LayoutAnimation from '@/components/framerAnimations/layoutAnimation';
import { ListItemComp } from '@/components/listItem';
import Loading from '@/components/loading';
import PageLinkComponent from '@/components/page/linkComponent';
import StyledImage from '@/components/styledImage';
import { useGraphQL } from '@/data';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import useAccountType from '@/helpers/useAccountType';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { planColors } from '@/pages/dashboard/settings/subscription/currentSubscription';
import { useModalControls } from '@/providers/modal';
import { QueryTiersReadArgs } from '@/types/schema';
import { gql } from '@apollo/client';
import {
	ArrowBackRounded as ArrowBackRoundedIcon,
	CheckRounded as CheckRoundedIcon,
	ClearRounded as ClearRoundedIcon,
	HelpCenterRounded as HelpCenterRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	Button,
	Chip,
	Container,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { capitalize, isNull } from 'lodash-es';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import type { Stripe } from 'stripe';
import { getSubscription } from './getSubscription';
import StripeSubscription from './stripeSubscription';
import { getInitialTier, TierObj, tiersObject } from './tiers';
import useSubscription from './useSubscription';

export function CheckItem( { value } ) {
	return typeof value === 'boolean'
		? value
			? <CheckRoundedIcon sx={{ color: 'success.main' }}/>
			: <ClearRoundedIcon sx={{ color: 'error.main' }}/>
		: (
			<Chip
				label={value}
				color='primary'
				variant='alpha'
			/>
		);
}

export default function SubscriptionModal( {
	showBackIcon = true,
	hasNavbar,
	navigateBackWithHistory,
}: {
	showBackIcon?: boolean,
	navigateBackWithHistory?: boolean,
	hasNavbar?: boolean
} ) {
	const { t } = useTranslation();
	const { closeModal } = useModalControls();
	const theme = useTheme();
	const { company, loading } = useCompany();
	const [ selectedTier, setSelectedTier ] = useState<TierObj>();
	const { active: isSubActive } = useSubscription();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	const [ initialLoading, setInitialLoading ] = useState( true );
	
	const { data } = useGraphQL<QueryTiersReadArgs>( {
		queryKey: [ 'tiers' ],
		query   : gql`
			query TiersRead_8b63($options: FilterOptions) {
				tiersRead(options: $options) {
					items {
						name
						sequence
					}
				}
			}
		`,
	} );
	
	// Fetch Stripe subscription data if not a Clover account
	const { data: stripeData } = useQuery<Stripe.Subscription>(
		[ 'stripeSubscription', company?.id ],
		async () => {
			const { data } = await axios.post( '/api/user/stripe/getSubscription', {
				id: company?.id,
			} );
			return data.subscription;
		}, { enabled: !isNull( isCloverAccount ) && isCloverAccount && Boolean( company?.id ) } );
	
	// Initialize selectedTier using useEffect
	useAsyncEffect( async () => {
		if ( !company || !data?.tiersRead ) return;
		try {
			console.debug( { subscription } );
			const initialTierName = getInitialTier( { subscription, tiers: data.tiersRead.items } );
			const initialTier = tiersObject.find( ( tier ) => tier.name === initialTierName );
			setSelectedTier( initialTier );
			setInitialLoading( false );
		} catch ( e ) {
			console.error( e );
		} finally {
			setInitialLoading( false );
		}
	}, [ company, data, initialLoading ] );
	
	const showStripePayment = !selectedTier?.name
		? false
		: !isSubActive
			? true
			: selectedTier.name !== subscription?.tier?.name.toUpperCase();
	
	const featuresArray = selectedTier ? Object.entries( selectedTier.features ) : [];
	const midIndex = Math.ceil( featuresArray.length / 2 );
	const subscriptionName = subscription?.tier?.name;
	if ( loading || initialLoading ) return <Loading/>;
	console.log( subscriptionName );
	const content = (
		<Stack
			sx={{
				bgcolor  : 'background.default',
				height   : '100%',
				width    : '100vw',
				overflowX: 'hidden',
				mt       : hasNavbar ? '-67px' : 'unset',
				overflowY: 'scroll !important',
			}}>
			<Box
				sx={{
					pt        : hasNavbar ? 15 : 5,
					display   : 'flex',
					background: theme.palette.mode === 'dark'
						? 'linear-gradient(180deg, #141E30 0%, #243B55 100%)'
						: 'linear-gradient(180deg, #f0f8ff 0%, #e6f2ff 100%)',
				}}>
				<Container maxWidth='xl' sx={{ alignSelf: 'end', pb: 4 }}>
					{showBackIcon && (
						<Button
							startIcon={<ArrowBackRoundedIcon/>}
							variant='text'
							sx={{ mb: 2 }}
							onClick={() => {
								if ( navigateBackWithHistory ) history.back();
								closeModal();
							}}>
							Go Back
						</Button>
					)}
					<Stack spacing={0.5}>
						<Typography variant='h1' fontWeight={500}>
							Let's upgrade your account
						</Typography>
						{subscription?.status === 'CANCELED' ? (
							<Typography variant='h5' fontWeight='500'>
								Your Subscription has been canceled
							</Typography>
						) : subscriptionName && (
							<Stack spacing={1} alignItems='center' direction='row'>
								<Typography variant='h5' fontWeight='500'>
									Your current plan is on
								</Typography>
								<Chip
									label={capitalize( subscriptionName )}
									variant='alpha'
									sx={{
										...planColors[ subscriptionName?.toUpperCase() ],
										color: '#ffffff',
									}}
								/>
							</Stack>
						)}
						{subscriptionName !== 'Trial' && (
							<Stack spacing={0.5}>
								{!isCloverAccount && subscription?.endDate && (
									<Typography color='text.secondary'>
										Your plan renews on {safeFormatInTimeZone( subscription?.endDate, 'PP' )} with your
										original subscribed amount
									</Typography>
								)}
								{subscriptionName && (
									<Box>
										<Tooltip
											title="You're grandfathered into your current subscription rate and won't be charged more unless you upgrade"
											placement='right'>
											<Stack direction='row' spacing={1} alignItems='center' sx={{ width: 'fit-content' }}>
												<Typography color='text.secondary'>
													Your account's subscription price has not changed
												</Typography>
												<HelpCenterRoundedIcon
													sx={{
														fontSize    : 16,
														borderRadius: 1,
														color       : 'text.secondary',
													}}
												/>
											</Stack>
										</Tooltip>
									</Box>
								)}
							</Stack>
						)}
					</Stack>
				</Container>
			</Box>
			<Container maxWidth='xl' sx={{ py: 3 }}>
				<Grid container spacing={5}>
					<Grid item xs={12} md={8}>
						<Stack mb={2}>
							<Typography variant='h5' fontWeight='500'>
								Choose A New Plan
							</Typography>
							<Typography color='text.secondary'>
								All plans come with a 14-day free trial
							</Typography>
						</Stack>
						<Stack direction={{ xs: 'column', md: 'row' }} alignItems='center' spacing={1}>
							{tiersObject.map( ( tier ) => {
								const selected = selectedTier?.name === tier.name;
								return (
									<Box
										key={tier.name}
										sx={{
											'transition'                  : 'background-color 0.3s',
											'bgcolor'                     : selected ? 'secondary.main' : 'background.paper',
											'p, span, .MuiTypography-root': {
												color: selected ? 'white' : undefined,
											},
											':hover'                      : {
												bgcolor: selected ? 'secondary.main' : 'divider',
											},
											'p'                           : 2,
											'cursor'                      : 'pointer',
											'borderRadius'                : 2,
											'width'                       : '100%',
											'borderColor'                 : 'divider',
										}}
										onClick={() => setSelectedTier( tier )}>
										<Stack spacing={1}>
											<Typography variant='h4' fontWeight='500' color='text.secondary'>
												{capitalize( tier.name )}
											</Typography>
											<Stack direction='row' alignItems='baseline' spacing={0.4}>
												<Typography variant='h2'>${tier.price}</Typography>
												<Typography color='text.secondary'>/mo</Typography>
											</Stack>
										</Stack>
									</Box>
								);
							} )}
						</Stack>
						<Stack spacing={3} mt={5}>
							<Stack>
								<Typography variant='h5' fontWeight='500'>
									Overview
								</Typography>
								<Typography color='text.secondary'>{selectedTier?.description}</Typography>
							</Stack>
							<Stack spacing={1}>
								<Typography variant='h5' fontWeight='500'>
									Features
								</Typography>
								<Paper
									variant='borderless'
									sx={{
										padding      : 2,
										display      : 'flex',
										flexDirection: { xs: 'column', md: 'row' },
										gap          : 2,
									}}>
									<Fragment>
										<List
											disablePadding
											sx={{
												flex         : 1,
												display      : 'flex',
												flexDirection: 'column',
												alignItems   : 'flex-start',
											}}>
											{featuresArray.slice( 0, midIndex ).map( ( [ _key, feature ], index ) => (
												<LayoutAnimation key={index} variant='fadeIn'>
													<Tooltip title={feature.tooltip} placement='right'>
														<ListItemButton key={index}>
															<ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
																<CheckItem value={feature.limiting}/>
															</ListItemIcon>
															<ListItemText
																primary={t( `settings:${feature.label}` )}
																primaryTypographyProps={
																	typeof feature.limiting === 'boolean'
																		? feature.limiting
																			? undefined
																			: { color: 'gray' }
																		: {
																			fontWeight: 500,
																			fontSize  : '14px !important',
																		}
																}
															/>
														</ListItemButton>
													</Tooltip>
												</LayoutAnimation>
											) )}
										</List>
										<List
											disablePadding
											sx={{
												flex         : 1,
												display      : 'flex',
												flexDirection: 'column',
												alignItems   : 'flex-start',
											}}>
											{featuresArray.slice( midIndex ).map( ( [ key, feature ], index ) => (
												<LayoutAnimation key={midIndex + index} variant='fadeIn'>
													<Tooltip title={feature.tooltip} placement='right'>
														<ListItemButton key={midIndex + index}>
															<ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
																<CheckItem value={feature.limiting}/>
															</ListItemIcon>
															<ListItemText
																primary={t( `settings:${feature.label}` )}
																primaryTypographyProps={
																	typeof feature.limiting === 'boolean'
																		? feature.limiting
																			? undefined
																			: { color: 'gray' }
																		: {
																			fontWeight: 500,
																			fontSize  : '14px !important',
																		}
																}
															/>
														</ListItemButton>
													</Tooltip>
												</LayoutAnimation>
											) )}
										</List>
									</Fragment>
								</Paper>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={12} md={4}>
						<Paper variant='borderless' sx={{ p: 2 }}>
							<Typography variant='h2'>Summary</Typography>
							{isCloverAccount ? (
								<Box sx={{ textAlign: 'center' }}>
									<Typography sx={{ mt: 2, color: 'text.secondary' }}>
										Your subscription is managed by Clover. Upgrade in only 2 steps.
									</Typography>
									<Grid item xs={12} className='centerGridItem'>
										<Box
											className='center'
											sx={{
												bgcolor     : '#ffffff',
												borderRadius: '50%',
												height      : 50,
												width       : 50,
											}}>
											<Typography color='#000000' variant='h5'>1</Typography>
										</Box>
										<StyledImage
											src='/images/changesubscription1.png'
											alt='Install desktop app'
											sx={{
												width : 250,
												height: 250,
											}}
										/>
									</Grid>
									<Grid item xs={12} className='centerGridItem'>
										<Box
											className='center'
											sx={{
												bgcolor     : '#ffffff',
												borderRadius: '50%',
												height      : 50,
												width       : 50,
											}}>
											<Typography color='#000000' variant='h5'>2</Typography>
										</Box>
										<StyledImage
											src='/images/changesubscription2.png'
											alt='Install desktop app'
											sx={{ width: 250, height: 250 }}
										/>
									</Grid>
									<Button
										sx={{ mt: 2, alignSelf: 'center', width: '100%' }}
										variant='contained'
										size='large'
										color='success'
										component={PageLinkComponent}
										href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'>
										Go to Clover App Marketplace
									</Button>
								</Box>
							) : (
								<Fragment>
									<ListItemComp
										primary='Monthly Price'
										primaryTypographyProps={{ color: 'text.secondary' }}
										rightComp={<Typography variant='h5'>${selectedTier?.price}</Typography>}
										listItemProps={{ disableGutters: true }}
									/>
									{( stripeData?.default_payment_method as Stripe.PaymentMethod )?.card?.last4 && (
										<Typography>
											Current Card:
											**** {( stripeData?.default_payment_method as Stripe.PaymentMethod ).card?.last4}
										</Typography>
									)}
									{showStripePayment && selectedTier && (
										<StripeSubscription
											selectedTier={selectedTier}
											onFinish={async () => closeModal?.()}
										/>
									)}
								</Fragment>
							)}
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</Stack>
	);
	
	if ( hasNavbar ) return (
		<SubscriptionLayout>
			{content}
		</SubscriptionLayout>
	);
	
	return content;
}
