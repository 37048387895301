import { SubscriptionTier } from '@/types/tiers';
import { isBefore } from 'date-fns';
import { isEmpty, sortBy } from 'lodash-es';

export type TierBaseSub = {
	status: string,
	external: string,
	externalKey?: string | null,
	tier: {
		name: string,
		sequence: number
	},
	endDate?: string,
	updatedAt: string
};

export const getSubscription = <T extends TierBaseSub>( {
	subscriptions,
	type,
	active,
}: {
	subscriptions?: Array<T> | null,
	type?: 'CLOVER' | 'STRIPE',
	active?: boolean
} ): TierBaseSub | null => {
	if ( !subscriptions || isEmpty( subscriptions ) ) return null;
	
	// alter trial subscription status if it has ended, cuz Clover Trial is not auto-cancelled
	subscriptions
		?.filter( ( sub ) => sub.tier.name === SubscriptionTier.TRIAL )
		.forEach( ( sub ) => {
			if ( !sub.endDate || isBefore( new Date( sub.endDate ), new Date() ) ) sub.status = 'CANCELED';
		} );
	
	const typedSubs = type
		? subscriptions.filter( ( sub ) => sub.external === type )
		: subscriptions;
	
	const subByTier = sortBy( typedSubs, [ 'tier.sequence' ] );
	const activeSubs = subByTier.filter( ( sub ) => sub.status === 'ACTIVE' );
	if ( activeSubs.length ) return activeSubs.pop()!; // Return active highest subscription
	if ( active ) return null; // Return null if active subscription is required
	
	const subByUpdatedAt = sortBy( typedSubs, [ 'updatedAt' ] );
	return subByUpdatedAt.pop()!; // Return latest subscription if no active subscription
};
