import { mutateGraphQL } from '@/data/apollo';
import { UserWrite } from '@/data/user.graphql';
import useShowSnackBarAfterReload from '@/hooks/useShowSnackBarAfterReload';
import NewCompanyFormModal from '@/modals/createNewCompany';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { usePopper } from '@/providers/popper';
import { MutationUserWriteArgs } from '@/types/schema';
import {
	AddCircle as AddCircleIcon,
	CheckCircle as CheckCircleIcon,
	ExpandMore as ExpandMoreIcon,
	Storefront as StorefrontIcon,
} from '@mui/icons-material';
import {
	Avatar,
	ListItemAvatar,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Tooltip,
} from '@mui/material';
import { useRouter } from 'next/router';

export default function SelectCompanyMenu() {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	const { showPopper } = usePopper();
	const { setCompanyId } = useCompanyId();
	const { showModal } = useModal();
	const showSnackBarAfterReload = useShowSnackBarAfterReload();
	
	return (
		<Tooltip title={staff?.company.name || ''}>
			<ListItemButton
				sx={{ borderRadius: 2, flexGrow: 0, p: 1, maxWidth: 500 }}
				onClick={async ( e ) => {
					if ( user?.clientOnly === true && !staff ) {
						await mutateGraphQL<MutationUserWriteArgs>( {
							mutation : UserWrite,
							variables: {
								input: {
									clientOnly: false,
								},
							},
						} );
						await router.push( '/welcome' );
						location.reload();
						return;
					}
					if ( !staff ) {
						return router.push( '/welcome' );
					}
					showPopper( ( { closePopper } ) => (
						<MenuList disablePadding sx={{ minWidth: 200, m: 0 }}>
							{user?.staffs.map( ( newStaff, index ) => (
								<MenuItem
									key={index}
									selected={staff.id === newStaff.id}
									sx={{ mb: .5, p: 1 }}
									onClick={() => {
										setCompanyId( newStaff.company.id );
										window.open( '/dashboard', '_self' );
										showSnackBarAfterReload( `You are now in ${newStaff.company.name}`, { variant: 'default' } );
										closePopper();
									}}>
									<ListItemAvatar sx={{ minWidth: 45 }}>
										<Avatar
											src={newStaff?.company?.logo}
											sx={{
												width  : 30,
												height : 30,
												bgcolor: newStaff?.company?.logo && '#ffffff',
												img    : { objectFit: 'contain' },
											}}>
											<StorefrontIcon/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primaryTypographyProps={{ variant: 'h5' }}
										primary={newStaff?.company?.name}
									/>
									{staff.id === newStaff.id && <CheckCircleIcon sx={{ ml: 5 }}/>}
								</MenuItem>
							) )}
							<MenuItem
								sx={{ mt: .5, p: 1 }}
								onClick={async () => {
									showModal( NewCompanyFormModal, { maxWidth: 'sm', fullPageModal: true } );
									closePopper();
								}}>
								<ListItemIcon>
									<AddCircleIcon fontSize='medium'/>
								</ListItemIcon>
								<ListItemText
									primary='Create a new company'
									primaryTypographyProps={{ variant: 'h5' }}
								/>
							</MenuItem>
						</MenuList>
					), e.currentTarget, { placement: 'bottom-end' } );
				}}>
				<ListItemAvatar sx={{ minWidth: 45 }}>
					<Avatar
						src={staff?.company.logo || null}
						sx={{
							width  : 35,
							height : 35,
							bgcolor: staff?.company.logo && '#ffffff',
							img    : { objectFit: 'contain' },
						}}>
						<StorefrontIcon/>
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={staff?.company?.name || 'Create Company'}
					primaryTypographyProps={{
						variant: 'h5',
						color  : !staff ? 'primary.main' : undefined,
						sx     : {
							whiteSpace  : 'nowrap',
							overflow    : 'hidden',
							textOverflow: 'ellipsis',
						},
					}}
				/>
				{staff && <ExpandMoreIcon sx={{ ml: 1, color: 'text.secondary' }}/>}
			</ListItemButton>
		</Tooltip>
	);
}
