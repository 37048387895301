import { PaidTier, Permission, SubscriptionTier, TierFeature, TierFeatures } from '@/types/tiers';
import tierPermissionsJson from '../../../../common/tier-permissions.json';

export const TiersMeta = tierPermissionsJson as {
	[key in PaidTier]: {
		price: number,
		features: TierFeatures
	}
};

// Function to determine the minimum plan required for the given permission
export const getPlanForPermission = ( permission: Permission ): PaidTier => {
	for ( const tier in TiersMeta ) {
		if ( TiersMeta[ tier ].features[ TierFeature[ permission ] ] ) return tier as PaidTier;
	}
	throw new Error( `Permission ${permission} not found in tier permissions` );
};

const featureLabels: { [key in TierFeature]: string } = {
	estimating       : 'tier-unlimited-estimates',
	invoicing        : 'tier-unlimited-invoices',
	orders           : 'tier-unlimited-orders',
	onlineOrders     : 'tier-online-stores',
	uniquePaymentLink: 'tier-unique-payment-links',
	teamMember       : 'tier-allowed-team-members',
	inventory        : 'tier-inventory-management',
	reports          : 'tier-reports',
	agreement        : 'tier-agreements',
	invoiceText      : 'tier-send-invoices-via-text',
	savedCards       : 'tier-saved-cards',
	surcharge        : 'tier-allow-surcharge',
	cashDiscounts    : 'tier-cash-discounts',
	purchasing       : 'tier-purchasing',
	houseAccounts    : 'tier-house-accounts',
	dispatchDrivers  : 'tier-dispatch-drivers',
	scannerSupport   : 'tier-scanner-support',
	deviceSupport    : 'tier-device-support',
	emailAnnouncement: 'tier-email-announcements',
	qbSync           : 'tier-quickbooks-sync',
	logging          : 'tier-activity-logging',
	teamCommission   : 'tier-team-commission-tracking',
};

export const tierDescriptions = {
	Essentials: 'Freelancers, individuals needing to create estimates and accept online payments for orders and invoices.',
	Pro       : 'Small businesses with a small team needing to create estimates, accept online payments for orders and invoices, generate detailed reports, and sync data with QuickBooks Online.',
	Premium   : 'Businesses with a larger team that need to create estimates, accept online payments for orders and invoices, generate detailed reports, sync data with QuickBooks Online, and interact with suppliers.',
	Enterprise: 'Businesses with more team members who need full access to all Invoiss features and updates, along with a dedicated account manager to oversee operations and assist with custom needs.',
};

const tierStyles = {
	ESSENTIALS: {
		bannerColor      : '#87cc99', // Light Green
		bannerBorderColor: '#87cc99',
	},
	PRO       : {
		bannerColor      : '#94bcf3', // Light Blue
		bannerBorderColor: '#94bcf3',
	},
	PREMIUM   : {
		bannerColor      : '#c2b1ef', // Light Purple
		bannerBorderColor: '#c2b1ef',
	},
	ENTERPRISE: {
		bannerColor      : '#f9bb82', // Light Peach
		bannerBorderColor: '#f9bb82',
	},
};
const generateTooltip = ( featureKey: string, limiting: number ): string => {
	const tooltips = {
		estimating       : 'Create unlimited estimates to provide your clients with detailed cost breakdowns.',
		invoicing        : 'Issue unlimited invoices to bill your clients for products or services rendered.',
		orders           : 'Manage unlimited orders efficiently to keep track of sales and purchases.',
		onlineOrders     : 'Allow customers to place orders online through your custom storefront.',
		uniquePaymentLink: 'Generate unique payment links for clients to pay invoices securely online.',
		teamMember       : ( limiting || 0 ) > 1
			? `Add up to ${limiting} team members with specific roles and permissions. There is a charge of $5 per month per extra member.`
			: 'Add one team member to help manage your account. There is a charge of $5 per month per extra member.',
		inventory        : 'Keep track of your stock levels with comprehensive inventory management.',
		reports          : 'Access detailed reports to gain insights into your business performance.',
		agreement        : 'Create and manage agreements or contracts directly within the platform.',
		invoiceText      : 'Send invoices via text message for quicker client notifications.',
		savedCards       : 'Save client card details securely for faster future transactions.',
		surcharge        : 'Automatically apply a surcharge to cover processing fees on transactions.',
		cashDiscounts    : 'Offer discounts to clients who pay with cash or check.',
		purchasing       : 'Streamline your purchasing process by creating purchase orders to suppliers.',
		houseAccounts    : 'Manage house accounts for clients with special billing arrangements.',
		dispatchDrivers  : 'Assign and dispatch drivers for delivery orders efficiently.',
		scannerSupport   : 'Use barcode scanners to quickly process items and manage inventory.',
		deviceSupport    : 'Access support for various devices to ensure smooth operations.',
		emailAnnouncement: 'Send mass email announcements to your client base for updates or promotions.',
		qbSync           : 'Synchronize your data seamlessly with QuickBooks Online.',
		logging          : 'Track user activities with detailed logs for compliance and monitoring.',
		teamCommission   : 'Manage and track commissions for your sales team members.',
	};
	return tooltips[ featureKey ] || '';
};

export type TierObj = {
	name: SubscriptionTier,
	price: number,
	description: string,
	style: { bannerColor: string, bannerBorderColor: string },
	features: { [ key: string ]: { limiting: number | boolean, label: string, tooltip: string } }
};

export const tiersObject: TierObj[] = Object.entries( TiersMeta ).map( ( [ tierName, tierData ] ) => ( {
	name       : tierName as SubscriptionTier,
	price      : tierData.price,
	description: tierDescriptions[ tierName ],
	style      : tierStyles[ tierName.toUpperCase() ],
	features   : Object.fromEntries( Object.entries( tierData.features )
		.filter( ( [ featureKey ] ) => featureLabels.hasOwnProperty( featureKey ) )
		.map( ( [ featureKey, limiting ] ) => [
			featureKey,
			{
				limiting,
				label  : featureLabels[ featureKey ],
				tooltip: generateTooltip( featureKey, limiting as number ),
			},
		] ),
	),
} ) );

export const getInitialTier = ( { subscription, tiers }: {
	subscription?: { tier: { name: string, sequence: number }, status: string } | null,
	tiers: { name: string, sequence: number }[]
} ): SubscriptionTier => {
	if ( !subscription?.tier ) return SubscriptionTier.ESSENTIALS;
	if ( subscription.status !== 'ACTIVE' && subscription.tier.sequence !== 1 ) return subscription.tier.name as SubscriptionTier;
	const maxSequence = Math.max( ...tiers.map( ( tier ) => tier.sequence ) );
	const nextSequence = Math.min( subscription.tier.sequence + 1, maxSequence );
	const nextTier = tiers.find( ( tier ) => tier.sequence === nextSequence );
	if ( !nextTier ) throw new Error( 'Next Tier not found' );
	return nextTier.name as SubscriptionTier;
};
