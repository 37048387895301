export enum SubscriptionTier {
	TRIAL = 'Trial',
	ESSENTIALS = 'Essentials',
	PRO = 'Pro',
	PREMIUM = 'Premium',
	ENTERPRISE = 'Enterprise',
}

export enum TierFeature {
	AGREEMENT = 'agreement',
	CASH_DISCOUNTS = 'cashDiscounts',
	DEVICE_SUPPORT = 'deviceSupport',
	DISPATCH_DRIVERS = 'dispatchDrivers',
	EMAIL_ANNOUNCEMENT = 'emailAnnouncement',
	ESTIMATES = 'estimating',
	HOUSE_ACCOUNTS = 'houseAccounts',
	INVENTORY = 'inventory',
	INVOICES = 'invoicing',
	INVOICE_TEXT = 'invoiceText',
	LOGGING = 'logging',
	ONLINE_STORES = 'onlineOrders',
	ORDERS = 'orders',
	PURCHASING = 'purchasing',
	QB_SYNC = 'qbSync',
	REPORTS = 'reports',
	SAVED_CARDS = 'savedCards',
	SCANNER_SUPPORT = 'scannerSupport',
	SURCHARGE = 'surcharge',
	TEAM_COMMISSION = 'teamCommission',
	TEAM_MEMBER = 'teamMember',
	UNIQUE_PAYMENT_LINK = 'uniquePaymentLink',
}

export type TierFeatures =
	{ [ TierFeature.TEAM_MEMBER ]: number }
	& { [key in Exclude<TierFeature, TierFeature.TEAM_MEMBER>]: boolean; };

export type Permission = keyof typeof TierFeature;

export type PaidTier = Exclude<SubscriptionTier, SubscriptionTier.TRIAL>;
