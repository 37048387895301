import PageLinkComponent from '@/components/page/linkComponent';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import {
	AccountCircleRounded as AccountCircleRoundedIcon,
	DashboardRounded as DashboardRoundedIcon,
	DeliveryDiningRounded as DeliveryDiningRoundedIcon,
	LockRounded as LockRoundedIcon,
} from '@mui/icons-material';
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Tooltip,
} from '@mui/material';
import { useRouter } from 'next/router';

export default function ProfileButton( {
	removeClientMode,
	removeCompanyMode,
	removeDriverMode,
	profileRoute,
}: {
	removeClientMode?: boolean,
	removeCompanyMode?: boolean,
	removeDriverMode?: boolean,
	profileRoute?: string
} ) {
	const { user, staff } = useUserInfo();
	const router = useRouter();
	const { showMenu } = useMenu();
	const route = router.route.split( '/' );
	const isDashboard = route[ 1 ] === 'dashboard';
	const isClient = route[ 1 ] === 'client';
	const isDriver = route[ 1 ] === 'driver';
	const isNotPublicRoute = router.route.split( '/' )[ 1 ] !== 'p';
	
	return (
		<Tooltip title='Profile'>
			<IconButton
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuList disablePadding sx={{ width: { sm: 200 }, m: 0, p: 0 }}>
						<MenuItem
							style={{ pointerEvents: 'none' }}>
							<ListItemText
								primary={`${user?.firstName} ${user?.lastName}`}
								primaryTypographyProps={{ fontWeight: 500 }}
								secondary={staff?.company.name}
							/>
						</MenuItem>
						<Divider sx={{ mb: '0 !important' }}/>
						<Box p={1}>
							{staff && isNotPublicRoute && !isDashboard && !removeCompanyMode && (
								<MenuItem
									component={PageLinkComponent}
									href='/dashboard'
									onClick={() => closeMenu()}>
									<ListItemIcon>
										<DashboardRoundedIcon/>
									</ListItemIcon>
									<ListItemText>Company Mode</ListItemText>
								</MenuItem>
							)}
							{staff && isNotPublicRoute && !isClient && !removeClientMode && (
								<MenuItem
									component={PageLinkComponent}
									href='/client'
									onClick={() => closeMenu()}>
									<ListItemIcon>
										<AccountCircleRoundedIcon/>
									</ListItemIcon>
									<ListItemText>Client Mode</ListItemText>
								</MenuItem>
							)}
							{staff && staff.isDriver && isNotPublicRoute && !isDriver && !removeDriverMode && (
								<MenuItem
									component={PageLinkComponent}
									href='/driver'
									onClick={() => closeMenu()}>
									<ListItemIcon>
										<DeliveryDiningRoundedIcon/>
									</ListItemIcon>
									<ListItemText>Driver Mode</ListItemText>
								</MenuItem>
							)}
							{!staff && (
								<MenuItem
									sx={{ m: 0 }}
									onClick={async () => {
										if ( profileRoute ) {
											await router.push( profileRoute );
										} else {
											await router.push( '/settings' );
										}
										closeMenu();
									}}>
									<ListItemIcon>
										<AccountCircleRoundedIcon fontSize='small'/>
									</ListItemIcon>
									<ListItemText>Profile Settings</ListItemText>
								</MenuItem>
							)}
							<MenuItem
								sx={{ 'm': 0, ':hover': { bgcolor: 'error.main', color: 'white' } }}
								onClick={async () => {
									closeMenu();
									await router.push( '/logout' );
								}}>
								<ListItemIcon>
									<LockRoundedIcon fontSize='small'/>
								</ListItemIcon>
								<ListItemText>Logout</ListItemText>
							</MenuItem>
						</Box>
					</MenuList>
				), e.currentTarget, { MenuListProps: { sx: { p: 0 } } } )}>
				<Avatar
					src={user?.image}
					sx={{
						width      : 35,
						height     : 35,
						fontSize   : 14,
						border     : 2,
						borderColor: 'text.primary',
						boxShadow  : 1,
					}}>
					{user.firstName?.[ 0 ]}{user.lastName?.[ 0 ]}
				</Avatar>
			</IconButton>
		</Tooltip>
	);
}
