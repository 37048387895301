import { HelpCenterRounded as HelpCenterRoundedIcon } from '@mui/icons-material';
import type {
	ListItemButtonProps,
	ListItemIconProps,
	ListItemProps,
	ListItemTextProps,
	TooltipProps,
} from '@mui/material';
import {
	Divider,
	Fade,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import type { ReactNode } from 'react';
import { Fragment } from 'react';

export default function LabeledText( { sx, ...props }: ListItemTextProps<'div', 'div'> ) {
	return (
		<ListItemText
			primaryTypographyProps={{ color: 'text.secondary' }}
			{...props}
			sx={{ my: 0, ...sx }}
			secondaryTypographyProps={{ fontWeight: 500, color: 'text.primary', ...props?.secondaryTypographyProps }}
		/>
	);
}

export function ListItemComp( {
	leftComp,
	rightComp,
	listItemProps,
	listItemIconProps,
	size,
	divider,
	tooltip,
	loading,
	rightNodeLoading,
	onClick,
	tooltipProps,
	...listItemTextProps
}: {
	leftComp?: ReactNode,
	rightComp?: ReactNode,
	listItemProps?: ListItemProps & ListItemButtonProps & { component?: ReactNode },
	listItemIconProps?: ListItemIconProps,
	size?: 'small' | 'medium' | 'large',
	divider?: boolean,
	tooltip?: ReactNode,
	loading?: boolean,
	onClick?: () => void,
	rightNodeLoading?: boolean,
	tooltipProps?: Omit<TooltipProps, 'children' | 'title'>
} & ListItemTextProps ) {
	
	const Component = onClick || listItemProps?.component ? ListItemButton : ListItem;
	
	if ( loading ) {
		return (
			<ListItem
				sx={{ py: size === 'small' ? 0 : size === 'medium' ? 2 : size === 'large' ? 3 : undefined }}
				{...listItemProps}>
				<ListItemText
					primaryTypographyProps={{ component: 'div' } as any}
					secondaryTypographyProps={{ component: 'div' } as any}
					{...listItemTextProps}
					primary={(
						<Skeleton
							animation='wave'
							height={10}
							sx={{ borderRadius: 1 }}
							width='25%'
						/>
					)}
					secondary={(
						<Skeleton
							animation='wave'
							height={10}
							sx={{ borderRadius: 1 }}
							width='35%'
						/>
					)}
				/>
				<Skeleton
					animation='wave'
					height={10}
					sx={{ borderRadius: 1 }}
					width='25%'
				/>
			</ListItem>
		);
	}
	
	return (
		<Fragment>
			<Fade in appear={false} timeout={1000}>
				<Component
					onClick={onClick}
					{...listItemProps}
					sx={{
						py: size === 'small' ? 0 : size === 'medium' ? 2 : size === 'large'
							? 3
							: undefined,
						...listItemProps?.sx,
					}}>
					{leftComp && <ListItemIcon sx={{ minWidth: 35 }} {...listItemIconProps}>{leftComp}</ListItemIcon>}
					<ListItemText
						primaryTypographyProps={{ component: 'div' } as any}
						secondaryTypographyProps={{ component: 'div' } as any}
						{...listItemTextProps}
						primary={tooltip ? (
							<Stack direction='row' alignItems='center' spacing={1}>
								<Typography {...listItemTextProps?.primaryTypographyProps}>{listItemTextProps.primary}</Typography>
								<Tooltip title={tooltip} placement='left' {...tooltipProps}>
									<HelpCenterRoundedIcon sx={{ fontSize: 16, borderRadius: 1, color: 'text.secondary' }}/>
								</Tooltip>
							</Stack>
						) : listItemTextProps.primary}
					/>
					{rightComp}
				</Component>
			</Fade>
			{divider && <Divider/>}
		</Fragment>
	);
}

