import { getSubscription } from '@/baseline/subscription/getSubscription';
import useSubscription from '@/baseline/subscription/useSubscription';
import LargeChip from '@/components/largeChip';
import PageSection from '@/components/page/section';
import useAccountType from '@/helpers/useAccountType';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import { useModal } from '@/providers/modal';
import { Tier } from '@/types/schema';
import { SubscriptionTier } from '@/types/tiers';
import { Payments as PaymentsIcon } from '@mui/icons-material';
import { Avatar, Chip, colors, Paper, Stack, SxProps, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import SubscriptionModal from 'src/baseline/subscription';
import CancelSubscriptionModal from './cancelSubscriptionModal';

export const planColors: Record<keyof typeof SubscriptionTier, SxProps<Theme>> = {
	TRIAL     : { bgcolor: colors.brown[ 500 ] },
	ESSENTIALS: { bgcolor: 'primary.main' },
	PRO       : { bgcolor: colors.deepPurple[ 500 ] },
	PREMIUM   : { bgcolor: 'secondary.main' },
	ENTERPRISE: { bgcolor: colors.deepOrange[ 500 ] },
};

export const SubscriptionStatus: Record<string, [ string, 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', string ]> = {
	ACTIVE            : [ 'Active', 'success', 'ToolTip' ],
	CANCELED          : [ 'Canceled', 'error', 'ToolTip' ],
	INCOMPLETE        : [ 'Incomplete', 'error', 'ToolTip' ],
	INCOMPLETE_EXPIRED: [ 'Incomplete Expired', 'error', 'ToolTip' ],
	PAST_DUE          : [ 'Past Due', 'error', 'ToolTip' ],
	TRIALING          : [ 'Trialing', 'warning', 'ToolTip' ],
	UNPAID            : [ 'Unpaid', 'warning', 'ToolTip' ],
};

export default function CurrentSubscription() {
	const { t } = useTranslation();
	const { showModal } = useModal();
	const { company } = useCompany();
	const isCloverAccount = useAccountType( 'CLOVER' );
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	const { daysLeft } = useSubscription();
	
	const statusTranslation = {
		ACTIVE            : t( 'settings:active' ),
		CANCELED          : t( 'settings:canceled' ),
		INCOMPLETE        : t( 'settings:incomplete' ),
		INCOMPLETE_EXPIRED: t( 'settings:incomplete-expired' ),
		PAST_DUE          : t( 'settings:past-due' ),
		TRIALING          : t( 'settings:trialing' ),
		UNPAID            : t( 'settings:unpaid' ),
	};
	
	const subscriptionIsActive = subscription?.status === 'ACTIVE';
	const isOnTrial = subscription?.tier.name === 'Trial';
	const tier = subscription?.tier as Tier;
	const subscriptionEndDate = subscription?.endDate && format( subscription.endDate, 'PP' );
	
	return (
		<PageSection
			primary={t( 'settings:current-subscription' )}
			actions={[ subscriptionIsActive && !isOnTrial && {
				name       : t( 'settings:cancel-subscription' ),
				buttonProps: { color: 'error' },
				onClick    : () => showModal( CancelSubscriptionModal, { maxWidth: 'sm' }, undefined ),
			}, {
				name       : t( 'settings:view-plans' ),
				buttonProps: { color: 'primary' },
				onClick    : () => showModal( SubscriptionModal, { variant: 'fullPageDialog' } ),
			} ]}>
			<Paper sx={{ p: 2 }}>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack spacing={2} direction='row' alignItems='center'>
						<Avatar>
							<PaymentsIcon/>
						</Avatar>
						<Stack>
							<Stack direction='row' spacing={1}>
								{tier?.name && (
									<Chip
										label={tier.name}
										variant='alpha'
										sx={{
											...planColors[ tier.name.toUpperCase() ],
											color: '#ffffff',
										}}
									/>
								)}
								<Typography>{t( 'settings:plan' )}</Typography>
							</Stack>
							<Typography color='text.secondary'>
								{isCloverAccount
									? t( 'settings:clover-subscription' )
									: isOnTrial
										? `${daysLeft} days left on trial, ends on ${subscriptionEndDate}`
										: !subscriptionIsActive
											? t( 'settings:subscription-canceled' )
											: t( 'settings:subscription-renew-date', { date: subscriptionEndDate || 'unknown' } )}
							</Typography>
						</Stack>
					</Stack>
					{SubscriptionStatus[ subscription?.status ] && (
						<LargeChip
							label={statusTranslation[ subscription?.status ]}
							color={SubscriptionStatus[ subscription?.status ][ 1 ]}
						/>
					)}
				</Stack>
			</Paper>
		</PageSection>
	);
}
