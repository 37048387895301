import CountryFlag from '@/components/countryFlag';
import { mutateGraphQL } from '@/data/apollo';
import { useMenu } from '@/providers/menu';
import { GTranslateRounded as GTranslateRoundedIcon } from '@mui/icons-material';
import { IconButton, ListItemIcon, MenuItem, MenuList, Tooltip } from '@mui/material';
import { groupBy, mapValues } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { UserWrite } from '../../../data/user.graphql';
import { MutationUserWriteArgs } from '../../../types/schema';

const LANGUAGES = [ {
	label: 'United States (English)',
	flag : 'us',
	value: 'en',
}, {
	label: 'Canada (Français)',
	flag : 'canada',
	value: 'fr',
}, {
	label: '中国（中文)',
	flag : 'china',
	value: 'cn',
}, {
	label: 'España (Español)',
	flag : 'spain',
	value: 'es',
} ];

const LANG_MAP = mapValues( groupBy( LANGUAGES, 'value' ), ( [ lang ] ) => lang );

export function LanguageMenuList( { closeMenu } ) {
	const { i18n } = useTranslation();
	
	return (
		<MenuList>
			{LANGUAGES.map( ( lang ) => (
				<MenuItem
					key={lang.value}
					onClick={async () => {
						await i18n.changeLanguage( lang.value );
						await mutateGraphQL<MutationUserWriteArgs>( {
							mutation : UserWrite,
							variables: {
								input: {
									language: lang.value,
								},
							},
						} );
						closeMenu();
					}}>
					<ListItemIcon>
						<CountryFlag country={lang.flag}/>
					</ListItemIcon>
					{lang.label}
				</MenuItem>
			) )}
		</MenuList>
	);
}

export default function LanguageButton() {
	const { showMenu } = useMenu();
	const { i18n } = useTranslation();
	
	return (
		<Tooltip title='Change Language'>
			<IconButton
				onClick={( e ) => showMenu( ( { closeMenu } ) =>
					<LanguageMenuList closeMenu={closeMenu}/>,
					e.currentTarget,
				)}>
				{i18n.language === 'en' ? <GTranslateRoundedIcon/>
					: <CountryFlag country={LANG_MAP[ i18n.language ].flag}/>}
			</IconButton>
		</Tooltip>
	);
}
